import { gql } from '@apollo/client'

export const GET_STUDIES_QUERY = gql`
  query StudyQuery($studyWhere: Study_bool_exp) {
    Study(where: $studyWhere, order_by: { name: asc }) {
      id
      infoDescription
      isBlocked
      isCollectingPHI
      createdAt
      name
      aims
      design
      leadInvestigator
      notes
      Study_Sites_aggregate(where: { Site: { isBlocked: { _eq: false } } }) {
        aggregate {
          count
        }
      }
      Study_Sites(where: { Site: { isBlocked: { _eq: false } } }) {
        Site {
          id
          Site_Users(where: { User: { isDisplayBlocked: { _eq: false } } }) {
            user_id
            User {
              id
            }
          }
        }
      }
      Study_Tasks_aggregate {
        aggregate {
          count
        }
      }
      Study_TaskBatteries_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
