import { useQuery } from '@apollo/client'
import { Pane, toaster } from 'evergreen-ui'
import React, { useEffect, useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { useGlobalStore } from '../hooks/useGlobalStore'
import { GET_REALM_USER_QUERY } from '../queries/getRealmUsers'
import { formStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import { REALM_ADMIN } from '../utils/constants'
import CheckboxField from './forms/CheckboxField'
import LoadingSpinner from './LoadingSpinner'
import { getHighestRole } from './UserDetails/userUtils'

const useSharedStyles = createUseStyles({
  ...formStyles,
  users: {
    height: '350px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  checkbox: {
    composes: '$darkerCheckbox',
    marginLeft: '20px',
    width: '100%',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '6px !important'
    }
  },
  checkboxSelected: {
    composes: '$checkbox',
    '& span': {
      backgroundColor: `${myTheme.colors.mediumTeal}`
    }
  },
  userDisabled: {
    composes: '$checkboxSelected',
    width: '88%',
    marginLeft: '57px',
    marginTop: '10px',
    fontSize: '12px',
    '& span': {
      padding: '12px !important',
      letterSpacing: '0.018rem',
      lineHeight: '16px'
    }
  },
  labelSpans: {
    marginTop: '0 !important'
  }
})

const UserLabel = ({ field }) => {
  const sharedClasses = useSharedStyles()
  const studiesCount = useMemo(() => {
    const studies = []
    for (const user of field?.user?.Site_Users || []) {
      for (const study of user?.Site?.Study_Sites || []) {
        if (
          study.Study?.id &&
          !studies.some(s => s.Study?.id === study.Study.id)
        ) {
          studies.push(study)
        }
      }
    }
    return studies.length
  }, [field])
  return (
    <Pane display="flex" justifyContent="space-between">
      <span
        className={sharedClasses.labelSpans}
        style={{ marginLeft: 0, paddingLeft: 0 }}
      >
        {field.user?.givenName || ''} {field.user?.familyName || ''}
      </span>
      <span
        className={sharedClasses.labelSpans}
        style={{
          marginLeft: '-20%',
          width: 'auto'
        }}
      >
        Assigned to {field.user?.Site_Users_aggregate?.aggregate?.count || 0}{' '}
        Sites in {studiesCount} Studies
      </span>
    </Pane>
  )
}

const USER_LIST_ERROR_MESSAGE =
  'Sorry, something went wrong getting the user list of checkboxes, please try again later.'

const UserList = ({ control, currentUsers = [] }) => {
  const sharedClasses = useSharedStyles()
  const { realmId } = useGlobalStore()
  const { fields, append } = useFieldArray({
    control,
    name: 'users'
  })
  const userChecks = useWatch({ control, name: 'users' })
  const controlledInputs = useMemo(
    () =>
      userChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...userChecks[idx]
      })),
    [fields, userChecks]
  )

  const { loading, data } = useQuery(GET_REALM_USER_QUERY, {
    skip: !realmId,
    variables: {
      usersWhere: { Realm_Users: { realm_id: { _eq: realmId } } }
    },
    onError: _ => toaster.danger(USER_LIST_ERROR_MESSAGE)
  })

  useEffect(() => {
    if (
      data?.User &&
      data.User.length > 0 &&
      (!controlledInputs || controlledInputs?.length === 0)
    ) {
      const users = [...data.User]
      append(
        users
          .filter(u => !u.isDisplayBlocked)
          .map(user => {
            const isRealmAdmin = getHighestRole(user) === REALM_ADMIN || false
            return {
              user,
              value:
                isRealmAdmin ||
                currentUsers.some(u => u?.User?.id === user?.id) ||
                false,
              disabled: isRealmAdmin
            }
          })
      )
    }
  }, [data])

  return (
    <Pane position="relative">
      {loading && <LoadingSpinner />}
      <p>Assign users to your site.</p>
      <Pane className={sharedClasses.users}>
        {controlledInputs?.map((field, index) => (
          <Pane key={field.id}>
            {field.disabled ? (
              <Pane className={sharedClasses.userDisabled}>
                <UserLabel field={field} />
              </Pane>
            ) : (
              <CheckboxField
                control={control}
                label={<UserLabel field={field} />}
                defaultValue={field.value}
                name={`users.${index}.value`}
                data-testid={`${field.user.id}-user-check`}
                className={
                  field.value
                    ? sharedClasses.checkboxSelected
                    : sharedClasses.checkbox
                }
              />
            )}
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

export default UserList
