import React, { useRef, useEffect } from 'react'
import { login, isAuthenticated } from '../../../../utils/auth'
import { Pane } from 'evergreen-ui'
import SiteDetails from '../../../../components/SiteDetails'
import { useGlobalStore } from '../../../../hooks/useGlobalStore'
import { useRoleAccess } from '../../../../hooks/useRoleAccess'
import { useHandleSitesList } from '../../../../hooks/useHandleSitesList'

const SitesPage = ({ params }) => {
  const { setRealmPath } = useGlobalStore()
  const { canEditSite, canOpenUserDetails } = useRoleAccess()
  const sitesSectionRef = useRef(null)

  const {
    sitesSet,
    handleSiteDetailsClose
  } = useHandleSitesList({ sitesSectionRef })

  useEffect(() => {
    setRealmPath('sites')
  }, [setRealmPath])

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }
  return (
    <>
      <SiteDetails
        siteId={params['siteId']}
        show={true}
        handleDetailsClose={handleSiteDetailsClose}
        parentSectionRef={sitesSectionRef}
        siteNames={sitesSet?.map(site => site.name)}
        canEdit={canEditSite}
        canOpenUserDetails={canOpenUserDetails}
      />
    </>
  )
}

export default SitesPage
