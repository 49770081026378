import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Pane, toaster } from 'evergreen-ui'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { GET_STUDIES_QUERY } from '../queries/getStudies'
import { formStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import CheckboxField from './forms/CheckboxField'
import LoadingSpinner from './LoadingSpinner'
import { useGlobalStore } from '../hooks/useGlobalStore'

const useSharedStyles = createUseStyles({
  ...formStyles,
  studies: {
    height: '350px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  checkbox: {
    composes: '$darkerCheckbox',
    marginLeft: '20px',
    width: '100%',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '10px !important'
    }
  },
  checkboxSelected: {
    composes: '$checkbox',
    '& span': {
      backgroundColor: `${myTheme.colors.mediumTeal}`
    }
  },
  labelSpans: {
    marginTop: '0 !important'
  }
})

const STUDY_LIST_ERROR_MESSAGE =
  'Sorry, something went wrong getting the study list checkboxes, please try again later.'

const StudyList = ({ control, currentStudies = [] }) => {
  const sharedClasses = useSharedStyles()
  const { realmId } = useGlobalStore()
  const { fields, append } = useFieldArray({
    control,
    name: 'studies'
  })
  const studyChecks = useWatch({ control, name: 'studies' })
  const controlledInputs = useMemo(
    () =>
      studyChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...studyChecks[idx]
      })),
    [fields, studyChecks]
  )
  const { loading, data } = useQuery(GET_STUDIES_QUERY, {
    skip: !realmId,
    variables: {
      studyWhere: {
        realm_id: { _eq: realmId },
        isBlocked: { _eq: false }
      }
    },
    onError: _ => toaster.danger(STUDY_LIST_ERROR_MESSAGE)
  })

  useEffect(() => {
    if (
      data?.Study &&
      data?.Study.length > 0 &&
      (!controlledInputs || controlledInputs?.length === 0)
    ) {
      append(
        data.Study.map(study => ({
          study,
          value: currentStudies.some(s => s.Study.id === study.id) || false
        }))
      )
    }
  }, [data])

  return (
    <Pane position="relative">
      {loading && <LoadingSpinner />}
      <p>Assign your site to studies in your realm.</p>
      <Pane className={sharedClasses.studies}>
        {controlledInputs?.map((field, index) => (
          <CheckboxField
            control={control}
            label={field.study?.name}
            key={field.id}
            defaultValue={field.value}
            name={`studies.${index}.value`}
            data-testid={`${field.study.id}-study-check`}
            className={
              field.value
                ? sharedClasses.checkboxSelected
                : sharedClasses.checkbox
            }
          />
        ))}
      </Pane>
    </Pane>
  )
}

export default StudyList
