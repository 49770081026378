import { Dialog, Pane, Button } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { customListStyles } from '../../styles/sharedComponentStyles'

const useSharedStyles = createUseStyles({
  ...customListStyles,
  buttons: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '93%'
  },
  textWrapper: {
    margin: '30px'
  }
})

const CreateConfirm = ({
  handleCloseModal,
  isShown,
  handleConfirm,
  isLoading
}) => {
  const sharedClasses = useSharedStyles()
  return (
    <Dialog
      isShown={isShown}
      title="Warning"
      onCloseComplete={handleCloseModal}
      hasCancel={false}
      hasFooter={false}
      confirmLabel="Confirm"
    >
      <Pane className={sharedClasses.textWrapper}>
        There are no studies assigned to your site. Are you sure you want to
        save?
      </Pane>
      <Pane className={sharedClasses.buttons}>
        <Button
          className={sharedClasses.buttonStylesSecondary}
          onClick={handleCloseModal}
        >
          Go Back
        </Button>
        <Button
          className={sharedClasses.buttonStylesDanger}
          isLoading={isLoading}
          onClick={handleConfirm}
          data-testid="accept-confirm"
        >
          Save
        </Button>
      </Pane>
    </Dialog>
  )
}

export default CreateConfirm
