import * as yup from 'yup'
import { validateRepeatedName } from '../../utils/filters'


export const siteFormSchema = siteNames =>
  yup.object().shape({
    name: yup
      .string()
      .typeError('Required')
      .required('Required')
      .trim()
      .test({
        message: 'A site with this name already exist, please change it',
        name: 'siteName',
        test: value => !validateRepeatedName(siteNames, value)
      })
  })

export const siteUsersManagementSchema = yup.object().shape({
  users: yup
    .array()
    .transform(task => task.filter(t => t.value))
    .required()
    .min(1)
})
